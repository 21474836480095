import React from "react";
import Helmet from "react-helmet";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleTheToggle } from "src/services/compareSerp/compareSerpActions";

import About from "src/components/page-specific/About/About";
import config from "data/SiteConfig";

// const AboutPage = ({ actions }) => (
// <Layout>
// 	<main>
// 		<Helmet title={`About | ${config.siteTitle}`} />
// 		<About />
// 		<button onClick={() => clickMe(actions)}>Push me</button>
// 	</main>
// </Layout>
// );

// export default AboutPage

// import React, { Component } from 'react';

class AboutPage extends React.Component {
	onClickButton = () => {
		console.log(this.props.actions);
		this.props.actions.toggleTheToggle();
	};

	render() {
		return (
			<main>
				<Helmet title={`About | ${config.siteTitle}`} />
				<About />
				<button type="button" onClick={() => this.onClickButton()}>
					Push me
				</button>
			</main>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators({ toggleTheToggle }, dispatch)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(AboutPage);

// export default about;
//
